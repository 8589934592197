
/* @import url('https://fonts.googleapis.com/css?family=Barlow'); */
@import url('https://fonts.googleapis.com/css?family=Barlow:ital,wght@1,800&display=swap');

/* header */
.nav-header {
  background-color: rgb(253, 129, 13);
  height: 10px;
}

.donate-btn {
  padding-left: 50px;
  padding-right: 50px;
}

/* body */
body {
  font-family: 'Barlow', cursive;
}

h4 {
  color: rgb(253, 129, 13);
  font-weight: bold;
}

.theme-text-orange {
  color: rgb(253, 129, 13);
}

.theme-text-super-bold {
  font-weight: 900;
}

.theme-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

/* home page */

.carousel-img {
  display: block;
  /* max-width:1600px; */
  max-height:1000px;
  width: auto;
  height: auto;
}

.theme-custom-container {
  background-color: #F8F8F8;
}

.container-difference {
  background-color: rgb(76, 117, 153);
}

.container-difference h1 {
  color: white;
}

/* footer */
.themeFooter {
  height: 150px;
}
.themeFooter a {
  color: white;
  text-decoration: none
}

/* visited link */
.themeFooter a:visited {
  color: green;
}

/* mouse over link */
.themeFooter a:hover {
  color: hotpink;
}

.themeFooter span {
  color: white;
}

.themeFooter p {
  color: white;
}
.themeFooter .themeFooterTop {
  background-color: rgb(4, 22, 49);
  height: 100px;
}
.themeFooter .themeFooterBottom {
  background-color: rgb(2, 31, 73);
  height: 50px;
}

/* Sub pages */
.theme-page-header {
  height: 150px;
  background-image: url("../images/pageHeader.jpg");
}