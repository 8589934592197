@import url(https://fonts.googleapis.com/css?family=Barlow:ital,wght@1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/* @import url('https://fonts.googleapis.com/css?family=Barlow'); */

/* header */
.nav-header {
  background-color: rgb(253, 129, 13);
  height: 10px;
}

.donate-btn {
  padding-left: 50px;
  padding-right: 50px;
}

/* body */
body {
  font-family: 'Barlow', cursive;
}

h4 {
  color: rgb(253, 129, 13);
  font-weight: bold;
}

.theme-text-orange {
  color: rgb(253, 129, 13);
}

.theme-text-super-bold {
  font-weight: 900;
}

.theme-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

/* home page */

.carousel-img {
  display: block;
  /* max-width:1600px; */
  max-height:1000px;
  width: auto;
  height: auto;
}

.theme-custom-container {
  background-color: #F8F8F8;
}

.container-difference {
  background-color: rgb(76, 117, 153);
}

.container-difference h1 {
  color: white;
}

/* footer */
.themeFooter {
  height: 150px;
}
.themeFooter a {
  color: white;
  text-decoration: none
}

/* visited link */
.themeFooter a:visited {
  color: green;
}

/* mouse over link */
.themeFooter a:hover {
  color: hotpink;
}

.themeFooter span {
  color: white;
}

.themeFooter p {
  color: white;
}
.themeFooter .themeFooterTop {
  background-color: rgb(4, 22, 49);
  height: 100px;
}
.themeFooter .themeFooterBottom {
  background-color: rgb(2, 31, 73);
  height: 50px;
}

/* Sub pages */
.theme-page-header {
  height: 150px;
  background-image: url(/static/media/pageHeader.a8555e51.jpg);
}
